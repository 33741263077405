
import store from '@/store';
import {
  computed, defineComponent,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';

import modals, { modalsNames } from '@/utils/modals';

import routeHash from '@/utils/routeHash';
import { FULFILLED, INIT } from '@/utils/statuses';
import { State } from '@/store/storeUtils';
import { useI18n } from 'vue-i18n';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalWithdrawUsdtAccept',
  components: {
    CommonButtonSecondary, CommonButtonPrimary, CommonModal, CommonLoader,
  },
  setup() {
    const { t } = useI18n();
    const withdrawState = computed(() => {
      const { state } = store.state.withdrawTokensStore;
      if (state === INIT) {
        return FULFILLED;
      }
      return state;
    });

    const modalName = modalsNames.withdrawUsdtAccept;

    const hash = computed(() => store.getters.routeStore.hash);
    const hideContent = computed(() => !hash.value.modal);
    const isDone = computed(() => !!hash.value.isDone || !hash.value.modal);

    const data = computed(() => ({
      // typeOfWallet: hash.value.typeOfWallet || 'erc20',
      typeOfWallet: hash.value.typeOfWallet || 'bep20',

      address: hash.value.address as string || '',
      iban: (hash.value.iban || '') as string,
      beneficiaryName: (hash.value.beneficiaryName || '') as string,

      sum: hash.value.sum as string || '',
      sumWithFee: hash.value.sumWithFee as string || '',
      fee: (hash.value.fee || '') as string,

      tab: (hash.value.tab || 'btc') as 'bsc',
    }));

    const closeHandler = modals.withdrawUsdtAccept.closeModal;
    const onBack = () => modals.withdrawUsdt.openModal(data.value);
    const onSend = () => {
      const {
        tab, sumWithFee, beneficiaryName, iban,
      } = data.value;

      const destinations = {
        bsc: `${data.value.typeOfWallet}|--|${data.value.address}`,
        paystree: `paystree|--|${iban}|--|${beneficiaryName}`,
      };

      const destination = destinations[tab] || '';

      store.dispatch.withdrawTokensStore.fetch({
        callback(state: State) {
          if (state === FULFILLED) {
            routeHash.replace({ ...data.value, isDone: '1' });
          }
        },
        amount: sumWithFee,
        destination,
        currency: 32,
      });
    };

    return {
      withdrawState,
      forms: {
        bsc: [
          {
            label: t('{currency} address', {
              currency: (String(data.value.typeOfWallet) || '').toUpperCase(),
            }),
            name: 'address',
          },
        ],
        paystree: [
          {
            label: 'Beneficiary name',
            name: 'beneficiaryName',
          },
          {
            label: 'IBAN',
            name: 'iban',
          },
        ],
      },
      modalName,
      isDone,
      hideContent,
      data,
      closeHandler,
      onBack,
      onSend,
    };
  },
});
