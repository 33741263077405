
import store from '@/store';
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import { modalsNames } from '@/utils/modals';
import { api } from '@/utils/apiInstance';
import {
  FULFILLED, INIT, PENDING,
} from '@/utils/statuses';
import { useI18n } from 'vue-i18n';
import snsWebSdk from '@sumsub/websdk';
import { State } from '@/store/storeUtils';

import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalKyc',
  components: { CommonModal },
  setup() {
    const { locale } = useI18n({ useScope: 'global' });
    const accessToken = ref<string>('');
    const tokenState = ref<State>(INIT);

    const hash = computed(() => store.getters.routeStore.hash);

    const userEmail = computed<string>(() => String(hash.value.email || ''));

    const modalName = modalsNames.kyc;
    const getToken = async () => {
      if (tokenState.value === PENDING) {
        return accessToken.value;
      }
      tokenState.value = PENDING;

      const response = await api.office.sendPost({
        url: '/kyc/apply',
        data: {
        },
      });
      tokenState.value = FULFILLED;

      const { token } = response.data.data;
      accessToken.value = token;

      return token;
    };

    let snsWebSdkInstance: any | undefined;

    const launchWebSdk = async () => {
      const token = await getToken();

      snsWebSdkInstance = snsWebSdk
        .init(token, getToken)
        // .withBaseUrl(apiUrl)
        .withConf({
          lang: locale.value,
          email: userEmail.value || '',
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on('idCheck.stepCompleted', (payload) => {
          console.info('stepCompleted', payload);
        })
        .on('idCheck.onError', (error) => {
          console.error('onError', error);
        })
        .onMessage((messageType, payload) => {
          console.info('onMessage', messageType, payload);
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container');
    };

    const currentModalOpen = computed(() => store.getters.routeStore.currentModalOpen);

    watch(currentModalOpen, (value) => {
      if (value === modalName) {
        launchWebSdk();
      } else if (snsWebSdkInstance) {
        snsWebSdkInstance.destroy();
      }
    });

    return {
      modalName,

    };
  },
});
