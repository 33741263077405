/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { AxiosResponse } from 'axios';

import { path } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  basicOfficeFetch, getFetchState, FetchStateInterface, getFetchMutations,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

export interface Balance {
  usdt: {
    balance: string;
    decimal_places:8
    id:23
    name:'USDT'
    slower:'usdt'
    symbol:'USDT'
    symbol2:'USDT'

  },
  saim: {
    id: '47';
    name: 'AimToken';
    symbol: 'SAIM';
    decimal_places: '2';
    slower: 'saim';
    symbol2: 'AIM';
    balance: string;
  };
  sgg: {
    id: '48';
    name: 'Google Grants';
    symbol: 'SGG';
    decimal_places: '2';
    slower: 'sgg';
    symbol2: 'GG';
    balance: string;
  };
  sspacad: {
    id: '46';
    name: 'SPACAD';
    symbol: 'SSPACAD';
    decimal_places: '2';
    slower: 'sspacad';
    symbol2: 'SPACAD';
    balance: string;
  };
  susdt: {
    id: '45';
    name: 'USDT';
    symbol: 'SUSDT';
    decimal_places: '2';
    slower: 'susdt';
    symbol2: 'USD';
    balance: string;
  };
}

export type balanceResponse = AxiosResponse<{ data: Balance }>;

const userBalanceStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<balanceResponse>(),
  } as FetchStateInterface<balanceResponse>,

  getters: {
    data(...args): Balance | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data.user_balance', state.response) as Balance;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/balance',
          data: {},
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in userBalanceStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default userBalanceStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userBalanceStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userBalanceStore);
