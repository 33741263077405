import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/ref/:place/:id',
    name: 'Ref',
    component: () => import(/* webpackChunkName: "ref" */ '@/views/Ref.vue'),
  },
  {
    path: '/login/join',
    name: 'Join',
    component: () => import(/* webpackChunkName: "join" */ '@/views/Join.vue'),
  },
  {
    path: '/login/new-password',
    name: 'AuthNewPassword',
    component: () => import(/* webpackChunkName: "authNewPassword" */ '@/views/AuthNewPassword.vue'),
  },
  {
    path: '/login/password-recovery',
    name: 'PasswordRecovery',
    component: () => import(/* webpackChunkName: "passwordRecovery" */ '@/views/PasswordRecovery.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "transactions" */ '@/views/Transactions.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/Contacts.vue'),
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import(/* webpackChunkName: "requests" */ '@/views/Requests.vue'),
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import(/* webpackChunkName: "contracts" */ '@/views/Contracts.vue'),
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
  },
  {
    path: '/profile',
    name: 'Profile2',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
  },
  {
    path: '/referralTree',
    name: 'ReferralTree',
    component: () => import(/* webpackChunkName: "ReferralTree" */ '@/views/ReferralTree.vue'),
  },
  {
    path: '/staking',
    name: 'Staking',
    component: () => import(/* webpackChunkName: "nodes" */ '@/views/Nodes.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  },
  {
    path: '/openContractsPage',
    name: 'OpenContractsPage',
    component: () => import(/* webpackChunkName: "404" */ '@/views/OpenContractsPage.vue'),
  },
  {
    path: '/u66jca9sw6lii70on5y9ghphtonto5',
    name: 'OpenWebsite',
    component: () => import(/* webpackChunkName: "OpenWebsite" */ '@/views/OpenWebsite.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
