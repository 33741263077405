<template>
  <button :class="['primaryButton', {primaryButton_forWithdraw: isWithdraw}]" :disabled="disable" @click="click" v-bind="$attrs">
    <span class="primaryButton__content">
      <slot />
    </span>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommonButtonPrimary',
  emits: ['click'],
  props: {
    disable: {
      type: Boolean,
      default: false,
      required: false,
    },
    isWithdraw: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(e) {
      if (!this.disable) {
        this.$emit('click', e);
      }
    },
  },
});
</script>

<style lang="stylus" scoped>
.primaryButton {
  padding: 12px 16px;
  user-select: none;

  +mediaTablet() {
    padding: 13px 16px;
  }

  border-radius: 4px;
  color: $colorFiller;
  background-color: $colorPrimary;
  border: 0;
  cursor: pointer;
  transition: all 0.1s;
  getFontButton();

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;

    >span {
      opacity: 0.2;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &__content {
    opacity: 1;
  }
  &_forWithdraw {
    &:disabled {
      color: $colorFontBase;
      background-color: $colorSecond;
      >span {
        opacity: 0.3;
      }
    }
  }
}
</style>
