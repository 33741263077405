
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CommonButtonMore',
  emits: ['click'],
  props: {
    size: {
      type: String as PropType<'sm' | 'md' | 'xl'>,
      require: false,
      default: 'md',
    },
    disable: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  methods: {
    click(e: any) {
      if (!this.disable) {
        this.$emit('click', e);
      }
    },
  },
});
