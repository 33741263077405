
import store from '@/store';
import { computed, defineComponent } from 'vue';
import modals, { modalsNames } from '@/utils/modals';
import curr from 'currency.js';
import routeHash from '@/utils/routeHash';
import changeCurr, { getCurr } from '@/utils/changeCurr';
import { pathOr } from 'rambda';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonModal from './CommonModal.vue';

import CommonLoader from './CommonLoader.vue';
import CommonTabs from './CommonTabs.vue';

export const tabsList = [
  { value: 'bep20', label: 'BEP20 address' },
];

export default defineComponent({
  name: 'ModalWithdrawOton',
  components: {
    CommonModal,
    CommonInput,
    CommonButtonPrimary,
    CommonLoader,
    CommonTabs,

  },
  setup() {
    const modalName = modalsNames.modalWithdrawOton;

    const frozenState = computed(() => store.state.userBalance.fetchState);
    const frozenData = computed(() => store.getters.userBalance.data);

    const hash = computed(() => store.getters.routeStore.hash);
    const balanceName = computed(() => hash.value.balance || 'USDT');

    const balancesList = [
      {
        name: 'USDT (adv)',
        currency: 'USDT',
        slower: 'vusdt',
      },
      {
        name: 'USDT',
        currency: 'USDT',
        slower: 'usdt',
      },
      {
        name: 'SPACAD',
        currency: 'SSPACAD',
        slower: 'sspacad',
      },
      {
        name: 'Google Grants',
        currency: 'SGG',
        slower: 'sgg',
      },
      {
        name: 'SUSDT',
        currency: 'SUSDT',
        slower: 'susdt',
      },
      {
        name: 'AimToken',
        currency: 'SAIM',
        slower: 'saim',
      },
    ];

    const findBalance = computed(() => balancesList.find((item: any) => item.name === hash.value.balance));

    const removeZerosAtEndOfTheString = (str: string) => {
      const arr = String(str).split('.');
      if (arr.length === 1) {
        return str;
      }
      const last = arr[arr.length - 1];
      const newLast = last.replace(/0+$/, '');
      if (newLast === '') {
        arr.pop();
      } else {
        arr[arr.length - 1] = newLast;
      }
      return arr.join('.');
    };

    const otonBalance = (balanceSlower: any, balanceFrozen: any) => computed(() => {
      if (!frozenData.value) {
        return { limit: '0' };
      }

      return {
        limit: removeZerosAtEndOfTheString(getCurr(balanceSlower, balanceFrozen).format()),
      };
    });

    const otonqBalance = computed(() => pathOr('0', 'balance.otonq.balance', store.getters.userStore.data));

    const otonqInNum = computed(() => Number(otonqBalance.value.replace(/,/g, '')));

    const otonqInEur = computed(() => changeCurr('oton', 'usd', otonqInNum.value));

    const otonqInBtc = computed(() => changeCurr('oton', 'btc', otonqInNum.value));

    const userData = computed(() => store.getters.userStore.data);
    const bep20 = computed(() => pathOr('', ['bep20'], userData.value));

    const data = computed(() => ({
      bep20: bep20.value || '',
      fee: hash.value.fee || 'usd',

      beneficiaryName: hash.value.beneficiaryName || '',
      iban: hash.value.iban || '',

      sum: hash.value.sum ? String(hash.value.sum) : '',
      tab: (tabsList.some(({ value }) => value === hash.value.tab) ? hash.value.tab : 'bep20') as 'bep20',
    }));

    const feesValues = computed(() => {
      const otonSum = curr((+data.value.sum || 0));
      const otonRate = Math.trunc(curr((+data.value.sum || 0)).multiply(0.97).value);
      let otonFee = otonSum.subtract(otonRate).value;

      if (otonFee < 3) {
        otonFee = 3;
      }

      return {
        oton: otonFee,
        eur: 1.5,
        btc: changeCurr('usd', 'btc', 1.5).format(),
      };
    });

    const sumWithFee = computed(() => {
      const withFee = curr(+data.value.sum || 0).subtract(feesValues.value.oton).value;
      return withFee > 0 ? withFee : 0;
    });

    const isValidSum = (balanceOton: any) => computed(() => {
      const sum = sumWithFee.value;
      const limit = +(balanceOton.limit?.replace(/,|\s/g, '') || 0);

      return (sum <= limit && Number(data.value.sum) >= 50);
    });

    const forms = computed(() => ({
      bep20: [
        {
          type: 'string',
          label: 'BEP20-address',
          name: 'bep20',
          addClass: 'fluid',
          get hasError() {
            return false;
          },
        },
      ],
    }));

    const formIsValid = computed(() => forms.value[data.value.tab]
      .every((form: any) => !form.hasError));

    const onChange = (name: string, value: string) => routeHash.replace({ [name]: value });
    const changeTab = (name: string) => {
      onChange('tab', name);
    };
    const onSubmit = () => modals.modalWithdrawOtonAccept.openModal(data.value);

    const ratesState = computed(() => store.state.exchangeRatesStore.fetchState);

    return {
      modalName,
      frozenState,
      frozenData,
      otonBalance,
      otonqBalance,
      otonqInNum,
      otonqInEur,
      otonqInBtc,
      data,
      sumWithFee,
      feesValues,
      onChange,
      onSubmit,
      changeTab,
      isValidSum,
      formIsValid,
      forms,
      tabsList,
      ratesState,
      balanceName,
      findBalance,
    };
  },
});
