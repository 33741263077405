import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dd6e61b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = {
  key: 0,
  class: "doneBox"
}
const _hoisted_3 = { class: "bodyItem" }
const _hoisted_4 = { class: "itemlabel" }
const _hoisted_5 = { class: "itemValue" }
const _hoisted_6 = { class: "i" }
const _hoisted_7 = { class: "itemlabel" }
const _hoisted_8 = { class: "itemValue" }
const _hoisted_9 = {
  key: 0,
  class: "doneButton"
}
const _hoisted_10 = {
  key: 1,
  class: "buttonBox"
}
const _hoisted_11 = { class: "buttonItem" }
const _hoisted_12 = { class: "buttonItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.isDone
    ? ' '
    : _ctx.$t('Withdraw from {currency} balance', { currency: _ctx.balanceName })
    
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonLoader, {
        state: _ctx.withdrawState,
        isPlaceholder: ""
      }, null, 8, ["state"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isDone)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t("Withdraw completed!")), 513), [
                [_vShow, !_ctx.hideContent]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Sum")), 1),
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.data.sum) + " ", 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.findBalance?.currency), 1)
          ]),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("div", { class: "itemInfo" }, _toDisplayString(_ctx.$t("{sum} fee", { sum: `${_ctx.feesValues.oton} ${_ctx.findBalance?.currency}` })), 513), [
              [_vShow, _ctx.data.tab === 'bep20']
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forms[_ctx.data.tab], (input) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "bodyItem",
            key: input.name
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(input.label)), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.data[input.name]), 1)
          ]))
        }), 128))
      ]),
      (_ctx.isDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_CommonButtonPrimary, { onClick: _ctx.closeHandler }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Done")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_CommonButtonSecondary, { onClick: _ctx.onBack }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Back")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_CommonButtonPrimary, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSend(_ctx.findBalance?.id || 49)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Accept and Withdraw {sum} {currency}", {
            sum: _ctx.sumWithFee,
            currency: _ctx.findBalance?.currency,
          })), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "title"]))
}