
import store from '@/store';
import { defineComponent, computed } from 'vue';
import { docsInfo, getTrHookGetDoc } from '@/utils/getDoc';
import { useI18n } from 'vue-i18n';
import { pathOr } from 'rambda';
import { useRoute } from 'vue-router';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import '@/assets/icons/filled-file-icon.svg?sprite';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalAcceptDocs',
  components: {
    CommonModal,
    CommonButtonPrimary,
    CommonLoader,
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' });
    const getDoc = getTrHookGetDoc();

    const path = computed(() => useRoute().path);
    const docsLinks = {
      'privacy-policy': 'https://profitfrom.space/privacy',
      'cookie-policy': 'https://profitfrom.space/cookies',
      'terms-of-use': 'https://profitfrom.space/terms',
    };

    const state = computed(() => store.state.userStore.fetchState);
    const docsForAccept = computed(() => store.getters.documentsAcceptionStore.docsForAccept);
    const needAccept = computed(() => store.getters.documentsAcceptionStore.needAccept && process.env.VUE_APP_DISABLE_DOCS !== 'true' && path.value !== '/404');
    const docsData = computed(() => docsForAccept.value.map((docName) => ({
      ...docsInfo[docName],
      link: docName,
    })));

    const content = {
      ru: [
        'Экосистема SpacAd постоянно развивается, предоставляя новые сервисы и продукты, совершенствуя функциональные возможности личных кабинетов и повышая их безопасность.',
        'Чтобы продолжить использование сайта https://portal.profitfrom.space/, нажмите кнопку «Принять». Этим вы соглашаетесь с условиями и принимаете официальные документы сайта.',
      ],
      en: [
        'SpacAd ecosystem is constantly grows, providing new services and products, improving functionality of dashboards and their security.',
        'To continue using https://portal.profitfrom.space/, press «Accept» button. By doing this you agree to the terms and conditions and accept the official documents of the site.',
      ],
    };

    const currentContent = pathOr(content.en, [locale.value], content);

    const onAccept = store.dispatch.documentsAcceptionStore.confirmAll;

    return {
      state,
      docsData,
      needAccept,
      currentContent,
      path,
      onAccept,
      docsLinks,
    };
  },
});
