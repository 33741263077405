

import store from '@/store';
import { computed, defineComponent } from 'vue';
import { multiply } from 'rambda';
import curr from 'currency.js';
import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import changeCurr from '@/utils/changeCurr';
import { useI18n } from 'vue-i18n';

import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonModal from './CommonModal.vue';
import CommonTabs from './CommonTabs.vue';
import CommonInputCheckbox from './CommonInputCheckbox.vue';

export const tabsList = [
  { value: 'bsc', label: 'ERC20/BEP20' },
  { value: 'paystree', label: 'OTON Paystree' },
];

export default defineComponent({
  name: 'ModalWithdrawUsdt',
  components: {
    CommonModal,
    CommonTabs,
    CommonInput,
    CommonButtonPrimary,
    CommonInputCheckbox,
  },
  setup() {
    const { t } = useI18n();
    const modalName = modalsNames.withdrawUsdt;

    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      typeOfWallet: hash.value.typeOfWallet || 'bep20',

      iban: hash.value.iban || '',
      address: hash.value.address || '',
      beneficiaryName: hash.value.beneficiaryName || '',

      sum: hash.value.sum ? String(hash.value.sum) : '',

      tab: (tabsList.some(({ value }) => value === hash.value.tab) ? hash.value.tab : 'bsc') as 'bsc',
    }));

    const commission = computed(() => ({
      bsc: {
        label: '',
        calc: () => 3,
      },
      paystree: {
        label: t('No fee'),
        calc: multiply(0),
      },
    }));

    const forms = computed(() => {
      const { iban } = data.value;
      return ({
        bsc: [
          {
            type: 'string',
            label: t('{currency} address', {
              currency: String(data.value.typeOfWallet || '').toUpperCase(),
            }),
            name: 'address',
            addClass: 'fluid',
            get hasError() {
              return false;
            },
          },
        ],
        paystree: [
          {
            type: 'string',
            label: 'Beneficiary name',
            name: 'beneficiaryName',
            addClass: '',
            get hasError() {
              return false;
            },
          },
          {
            type: 'string',
            label: 'IBAN',
            name: 'iban',
            addClass: '',
            mask: 'GB ## XXXXXX XXXXX XXXXXXX',
            placeholder: 'GB',
            get hasError() {
              if (!iban) {
                return false;
              }

              return String(iban).replace(/\s/g, '').length !== 22;
            },
          },
        ],
      });
    });

    const formIsValid = computed(() => forms.value[data.value.tab]
      .every((form: any) => !form.hasError));

    const sumInEur = computed(() => changeCurr('usdt', 'usd', data.value.sum)
      .format());
    const oneUsdtInEur = computed(() => changeCurr('usdt', 'usd', 1)
      .format());

    const usdtRateSettings = store.getters.exchangeRatesStore.ratesSettings.usdt;
    const fee = computed(() => curr(
      commission.value[data.value.tab].calc(),
      usdtRateSettings,
    ));

    const sumWithFee = computed(() => fee.value.add(data.value.sum));

    const isSumVaid = computed(() => {
      const sum = +data.value.sum || 0;
      let isValid = true;

      if (data.value.tab === 'bsc') {
        isValid = sum >= 0;
      }

      return isValid;
    });

    const onChange = (name: string, value: string) => {
      routeHash.replace({ [name]: value });
    };
    const changeTab = (name: string) => {
      onChange('tab', name);
    };
    const onSubmit = () => modals.withdrawUsdtAccept.openModal({
      ...data.value,
      fee: fee.value.format(),
      sumWithFee: sumWithFee.value.value,
    });

    const ratesState = computed(() => store.state.exchangeRatesStore.fetchState);

    return {
      modalName,
      tabsList,
      forms,
      data,
      commission,
      fee,
      sumWithFee,
      onChange,
      onSubmit,
      changeTab,
      isSumVaid,
      formIsValid,
      changeCurr,
      sumInEur,
      oneUsdtInEur,
      ratesState,
    };
  },
});
