
import {
  defineComponent, onBeforeMount, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import store from './store';

import ModalTwoAuth from './components/ModalTwoAuth.vue';
import ModalTransactionInfo from './components/ModalTransactionInfo.vue';
import ModalTransferBtc from './components/ModalTransferBtc.vue';
import ModalTransferUsdt from './components/ModalTransferUsdt.vue';
import ModalTransferBtcAccept from './components/ModalTransferBtcAccept.vue';
import ModalTransferUsdtAccept from './components/ModalTransferUsdtAccept.vue';

import ModalWithdrawBtc from './components/ModalWithdrawBtc.vue';
import ModalWithdrawBtcAccept from './components/ModalWithdrawBtcAccept.vue';
import ModalWithdrawEuro from './components/ModalWithdrawEuro.vue';
import ModalWithdrawEuroAccept from './components/ModalWithdrawEuroAccept.vue';
import ModalWithdrawOton from './components/ModalWithdrawOton.vue';
import ModalWithdrawOtonAcept from './components/ModalWithdrawOtonAcept.vue';

import ModalWithdrawUsdt from './components/ModalWithdrawUsdt.vue';
import ModalWithdrawUsdtAccept from './components/ModalWithdrawUsdtAccept.vue';
import TechWorksPlaceholder from './views/TechWorksPlaceholder.vue';
import ModalInfo from './components/ModalInfo.vue';
import ModalConfirm from './components/ModalConfirm.vue';
import ModalContractsInfo from './components/ModalContractsInfo.vue';
import Ref from './utils/Ref';
import ModalAcceptDocs from './components/ModalAcceptDocs.vue';
import { reLogin } from './utils/regExp/loginPage';
import ModalPurchasesInfo from './components/ModalPurchasesInfo.vue';
import ModalAllBranches from './components/ModalAllBranches.vue';
import ModalPaymentFail from './components/ModalPaymentFail.vue';
import ModalKyc from './components/ModalKyc.vue';
import ModalPaymentSuccess from './components/ModalPaymentSuccess.vue';

import ModalNewContract from './components/ModalNewContract.vue';

export default defineComponent({
  components: {
    ModalTransactionInfo,
    ModalTransferBtc,
    ModalTransferBtcAccept,
    ModalWithdrawBtc,
    ModalWithdrawBtcAccept,
    ModalWithdrawEuro,
    ModalWithdrawEuroAccept,
    ModalWithdrawOton,
    ModalWithdrawOtonAcept,
    ModalPaymentFail,
    ModalPaymentSuccess,
    ModalTwoAuth,
    ModalWithdrawUsdt,
    ModalWithdrawUsdtAccept,
    ModalTransferUsdt,
    ModalTransferUsdtAccept,
    TechWorksPlaceholder,
    ModalInfo,
    ModalContractsInfo,
    ModalConfirm,
    ModalAcceptDocs,
    ModalPurchasesInfo,
    ModalAllBranches,
    ModalNewContract,
    ModalKyc,
  },
  name: 'App',

  setup() {
    const { replace } = useRouter();
    const { locale } = useI18n();
    const hasTestCookie = Cookies.get('tester') === '527';
    let showTechWorks = false;

    Ref.save();

    if (hasTestCookie) {
      showTechWorks = false;
    }

    if (showTechWorks) {
      return { showTechWorks, locale };
    }

    onBeforeMount(() => {
      const { pathname } = window.location;

      const { isAuth } = store.state.userStore;

      if (isAuth) {
        store.dispatch.userStore.fetch();
        if (reLogin.test(pathname)) {
          replace({ path: '/' });
        }
      }

      if (!isAuth && !reLogin.test(pathname)) {
        replace({ path: '/login' });
      }
    });

    onMounted(() => {
      store.dispatch.exchangeRatesStore.fetch();
    });

    return {
      locale,
      showTechWorks,
    };
  },
});
